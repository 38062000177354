import styled from 'styled-components';
import { TEXT } from '@etiquette-ui/colors';
import { device, minDevice } from './Theme';

const ContainerFluid = styled.div`
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;

    color: ${({ dark }) => (dark ? TEXT : '#fff')};
    text-align: ${({ center }) => (center ? 'center' : 'left')};

    &.nested {
        padding-left: 0px;
        padding-right: 0px;
    }

    .max900{
        max-width: 900px;
        margin: 0 auto;

        .overflow {
            @media ${device.mobileS} { 
                font-size: 25px;
            } 
        }
    }

    @media ${device.laptopS} {
        padding: 0px 15px;
    }

    @media ${minDevice.laptopS} {
        max-width: 1140px;
    }

    @media ${minDevice.desktopM} {
        max-width: 1320px;
    }
`;

const TwoColumns = styled.div`
    max-width: 100%;
    position: relative;
    overflow: visible;
    margin-top: 35px;
    margin-bottom: 50px;

    @media ${minDevice.mobile}{
        margin-top: 80px;
        margin-bottom: 60px;
    }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${minDevice.mobile}{
    position: absolute;
    top: 25px;
    left: 0px;
    right: 0px;
  }
`;


const Section = styled.div`
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background: ${({ color }) => (color ? color : 'transparent')}; 
    overflow-x: hidden;
`;
const Space = styled.div`
    margin: 0;
    padding: 0;
    height: ${({ size }) => (size ? `${size}px` : '30px')};
`;
const Center = styled.div`
    text-align: center;

    h2 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    p{
        max-width: 536px;
        margin: 0 auto;
    }
`;
const ImageContainer = styled.div`
    max-width: 100%;
    margin-left: -20px;
    margin-top: 10px;
    margin-bottom: 30px;

    .mask-container {
        width: 100%;
        height: 100%;
        overflow: visible;
    }
    .img-fluid{
        width: auto;
        height: 100%;
    }

    @media ${minDevice.mobile}{
        max-width: 58.333333%;
        margin-left: auto;
        margin-top: 0px;
        height: 580px;
    }
    @media ${minDevice.ipad}{
        max-width: 66.666666%;
    }
    @media (min-width: 2000px) {
        max-width: 60%;
    }
`;
const InfoContainer = styled.div`
    max-width: 100%;
    flex: 0 0 100%;

    h3, p {
        margin-top: 0px;
    }
    h3 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 40px;
        @media ${minDevice.ipad}{
            margin-bottom: 60px;
        }
    }

    @media ${minDevice.mobile}{
        max-width: 41.666666%;
        flex: 0 0 41.666666%;
    }
    @media ${minDevice.ipad}{
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
    }
`;
const Grid = styled.div`
    margin-top: 60px;
    padding-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    justify-items: center;
    grid-template-areas: "col-1 col-2 col-3";

    @media ${device.mobile} { 
        margin-top: 30px;
        grid-column-gap: 20px;
    }
    @media ${device.tablet} { 
        margin-top: 80px;
        grid-template-columns: 100%;
        grid-template-areas: "col-2"
                            "col-1"
                            "col-3";
    }

    h3, p{
        margin: 0;
        padding: 0 0 24px 0; 
        max-width: 352px;
        @media ${device.tablet} { 
            max-width: 100%;
            padding-bottom: 0px;
        }
    }

    h3 {
        @media ${device.tablet} { 
            margin-bottom: 10px;
        }
    }

    p {
        @media ${device.tablet} { 
            margin-bottom: 30px;
        }
    }

    img{
        object-fit: contain !important;
    }

    .col-1 {
        grid-area: col-1;
        padding-top: 100px;
        justify-self: right;
        
        @media ${device.mobile} { 
            padding-top: 40px;
        }
        @media ${device.tablet} { 
            justify-self: center;
            align-self: start;
            padding-top: 0px;
        }
    }
    .col-2 {
        grid-area: col-2;
        @media ${minDevice.tablet} { 
            margin-left: -50px;
        }
        @media ${device.tablet} { 
            max-width: 355px;
            margin-left: -60px;
        }
    }
  
    .col-3 {
        grid-area: col-3;
        padding-bottom: 100px;
        justify-self: left;
        align-self: end;
        
        @media ${device.tablet} { 
            justify-self: center;
            padding-bottom: 0px;
        }
    } 

    .col-1, 
    .col-3 {
        @media ${device.tablet} { 
            width: 100%;
        }
    }
`;
const ThreeColumns = styled.div`
    padding: 100px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr) auto;
    grid-column-gap: 20px;
    justify-items: center;

    &.fluid {
        
        .col-3 {
        justify-self: left;
        }
        @media (min-width: 769px) {
            grid-template-columns: 1.1fr .75fr .5fr;
        }
        @media ${minDevice.desktopM} {
            grid-template-columns: 40% repeat(2, minmax(auto, 30%));
        }
    }

    @media ${minDevice.laptopS} {
        grid-column-gap: 40px;
    }

    @media ${device.mobile} { 
        margin-top: 30px;
    }
    @media ${device.tablet} { 
        margin-top: 0px;
        grid-template-columns: 100%;
        text-align: center;
        grid-column-gap: 20px;
    }

    h2 {
        font-size: 32px;
        margin-top: 0px;
        margin-bottom: 20px;
        @media ${minDevice.tablet} {
            font-size: 25px;
        }
        @media ${minDevice.ipad} {
            font-size: 32px;
        }
        @media ${minDevice.laptopS} {
            font-size: 48px;
        }
    }

    p {
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 40px;
        @media ${minDevice.tablet} {
            font-size: 16px;
        }
    }

    .col-1 {
        justify-self: right;
        width: 100%;
        @media ${device.tablet} { 
            order: 1;
        }
        .img-container {
           width: 100%;
           max-width: fit-content;

            &.overflow-fluid-md {
                @media ${minDevice.tablet} { 
                    height: 340px;
                    width: auto;
                    overflow: visible;
                    position: relative;
                }
                @media ${minDevice.laptopS} { 
                    height: auto;
                    width: 100%;
                }
            }
        }
        .img-fluid {
            width: 100%;
            height: auto;
        }
        .overflowed-img {
            width: 100%;
            width: auto;
            @media ${minDevice.tablet} { 
                right: 0px;
                left: auto;
            }
        }
    }
  
    .col-3 {
        justify-self: right;
        align-self: center;
        width: 250px;
        height: 330px;
        position: relative;
        @media ${device.tablet} { 
            order: 2;
            width: 100%;
            height: auto;
        }

        .img-container {
            height: 320px;
            min-width: 100%;
            overflow: hidden;
            margin-left: -15px;
            margin-right: -15px;

            @media ${minDevice.tablet} { 
                height: 100%;
                width: auto;
                overflow: visible;
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        .overflowed-img {
            height: 100%;
            width: auto;
            @media ${device.tablet} { 
                width: 130%;
                margin-left: -15%;
            }
        }
    } 

    .center {
        @media ${minDevice.laptopS} { 
            margin-left: -50px;
        }

        @media ${device.tablet} { 
            order: 0;
        }
    }

`;
export {
    Row,
    ContainerFluid,
    Container,
    Section,
    Space,
    Center,
    TwoColumns,
    InfoContainer,
    ImageContainer,
    Grid,
    ThreeColumns
};