import styled, {keyframes} from 'styled-components';
import { TEXT, PRIMARY } from '@etiquette-ui/colors';
import { device, minDevice } from '../../Theme';
import { Link } from 'gatsby';


const FadeButtonAnimationTop = keyframes`
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
const FadeButtonAnimationBottom = keyframes`
    0%, 30%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Container = styled.nav`
    background: #fff;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99999;
    color: ${TEXT};
    padding: 15px 10px;

    
    font-size: 18px;

    @media ${device.tablet} {
        display: flex;
        padding: 10px 15px;
        height: auto;
        justify-items: center;
        align-items: center;
        transition: 0.8s all ease;
    }

    .row {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 0px;
        margin: auto;

        @media ${device.tablet} {
            max-width: 100%;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -16px;
                left: -15px;
                right: -15px;
                z-index: 1;
                height: 130%;
                background-color: #fff;
            }
        }

        @media ${minDevice.laptopS} {
            max-width: 1140px;
        }

        @media ${minDevice.desktopM} {
            max-width: 1320px;
        }
    }
    
    .logo{
        grid-area: logo;
        justify-self: left;
        align-self: center;
        justify-content: center;
        z-index: 2;
        background: #fff;
        position: relative;

        margin-left: -10px;

        @media ${device.tablet} {
            display: flex;
            align-items: center;
            width: 100%;

            transition-property: margin-right;
            transition-duration: .7s;

            margin-right: ${({ click }) => (click ? '0px' : '75%')};
            padding-left: ${({ click }) => (click ? '0px' : '25px')};
            background-color: #fff;

            > a {
                transition-duration: 0.8s;
                transform: ${({ click }) => (click ? 'scale(1.18) translateY(5px) translateX(-5px)' : 'scale(1)')};
            }
            
        }

        @media ${device.mobileM} {
            margin-right: ${({ click }) => (click ? '0px' : '50%')};
        }
    }

    .button{
        grid-area: button;
        justify-self: right;
        font-size: 14px;
        width: 155px;
        margin-left: auto;
        z-index: 3;

        @media ${device.tablet} {
            display: grid;
            justify-self: left;

            position: fixed;

            &.--onTop {
                top: auto;
                left: auto;
                bottom: auto;
                right: 15px;
                animation: ${FadeButtonAnimationTop} 1s;
            }

            &.--onBottom {
                top: auto;
                left: 30%;
                bottom: 5%;
                right: 30%;
                width: 40%;
                max-width: 40%;
                animation: ${FadeButtonAnimationBottom} 1s;
            }
        }

        @media ${device.mobile} {
            max-width: 155px;
            width: 100%;
        }
        @media ${device.mobileL} {
            max-width: 125px;
            width: fit-content;
        }

        
    }

    .menu {
        @media ${device.tablet} {
            transition-property: height;
            transition-duration: .6s;
            height: ${({ click }) => (click ? '95vh' : '0px')};
            overflow: hidden;
            position: absolute;
            top: 100%;
            left: -15px;
            right: -15px;
            pointer-events: ${({ click }) => (click ? 'none' : 'all')};
        }
    }
`;
const MobileIcon = styled.div`
    display: none;

    @media ${device.tablet} {
        display: block;
        position: absolute;
        top: 12px;
        left: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;
const CenterItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Title = styled.div`
    font-size: 22px;
    font-weight: 600;
    color: ${TEXT};
    
    @media ${device.tablet} {
        font-size: 15px;
    }
`;
const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    background: #fff;
    margin-top: auto;
    margin-bottom: auto;

    li {
        list-style: none;
    }

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 400px 50px 40px;
        padding-inline-start: 0px;

        justify-content: center;
        align-items: start;

        z-index: 0;

        width: 100vw;
        position: absolute;
        height: 95vh;
        top: 0px;
        left: 0px;
        transition-duration: 1s;
        pointer-events: ${({ click }) => (click ? "all" : "none")};
    }
`;

const Dropdown = styled.ul`
    position: absolute;
    top: 60px;
    padding: 0px;
    box-shadow: 0px;
    border-radius: 24px;   
    
    flex-direction: column;
    align-items: center;

    @media (min-width: 769px){
        height: 0px;
        overflow: hidden;
        transition-property: height;
        transition-duration: .5s;
    }

    @media ${device.tablet} {
        display: flex;

        .card {
            box-shadow: none!important;
            padding: 8px 0px 0px 2px;
            border-radius: 0px;
        }
    }
`;

const DropdownContainer = styled.div`
        height: 100%;
        &:hover{
            ${Dropdown}{
                @media (min-width: 769px){
                    height: 345px;  
                    box-shadow: rgb(3 50 105 / 16%) 0px 6px 18px;                  
                }
            }            
        }
`;

const DropdownTitle = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 14px;

    @media ${device.tablet} {
        text-align: left;
        padding: 1.6rem 0;
        width: 100%;
        display: table;
        font-weight: 600;
        color: ${TEXT};
        font-size: 32px;    
    }
`;

const DropdownItem = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 8px 0;
    color: ${TEXT};
    font-size: 15px;
    
    &:hover {
        color: ${PRIMARY};
        transition: all 0.3s ease;
    }

    @media ${device.tablet} {
        text-align: left;
        width: 100%;
        display: table;    
        font-size : 18px;
        padding: 7px 0;
    }
`;

const NavLinks = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    color: ${TEXT};
    
    &:hover {
        color: ${PRIMARY};
        transition: all 0.3s ease;
    }

    @media ${device.tablet} {
        text-align: left;
        padding: 0px;
        padding-left: 3px;
        width: 100%;
        display: table;
        font-weight: 600;
        color: ${TEXT};
        font-size: 32px;    
    }
`;


const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    height: fit-content;
`;

const IconContainer = styled.div`
        width: 20px;
        height: 20px;
        margin-top: 4px;
        margin-left: 3px;

        @media ${device.tablet} {
            display: none;
        }
`;

export {
    DropdownItem,
    Dropdown,
    DropdownContainer,
    IconContainer,
    LogoLink,
    DropdownTitle,
    NavLinks,
    NavMenu,
    Title,
    CenterItem,
    MobileIcon,
    Container
};