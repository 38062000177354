import React from 'react';
import styled from 'styled-components';
import { PRIMARY } from '@etiquette-ui/colors';

const Container = styled.div`
    display: inline-block;
    position: relative;
    height: fit-content;
    width: fit-content;
    margin: 0;
    padding: 0;
`;

const StickyLine = styled.div`
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 5px;
    height: 8px;
    width: 100%;
    background-color: ${PRIMARY};
    z-index: 1;
`;
const TextWrap = styled.div`
    position: relative;
    z-index: 2;
`;

const Highlight = ({ text }) => {

    return (
        <Container>
            <TextWrap>{text}</TextWrap>
            <StickyLine />
        </Container>
    );
};

export default Highlight;