import React from 'react';
import { BG_DARK } from '@etiquette-ui/colors';
import { Section } from '../../GlobalStyles';
import Icon from '@etiquette-ui/icons';
import {
    Container,
    AboutIcon, 
    customIcon,
    Row
} from './styles';
import Preview from '../../i18n/Preview';
import useI18n from '../../i18n/useI18n';

const Footer = () => {
    const { translate, translateObject } = useI18n();
        
    return(
        <Section color={BG_DARK}>
            <Container>
                <Row>
                    <div className='description'>
                        <h1 className='description-title'>
                            <a href="https://www.linkedin.com/company/appsinti" target="_blank">
                                Appsinti Dev LLC
                            </a>
                        </h1>
                        <p>{translateObject({
                            en: 'A US and Mexico based business software development company.',
                            es: 'Una empresa de desarrollo de software empresarial con sede en Estados Unidos y México.'
                        })}</p>
                    </div>

                    <div className='contact'>
                        <h6 className='contact-title'>{translateObject({
                            en: 'Contact us',
                            es: 'Contáctanos'
                        })}</h6>
                        <a className='contact-item'>
                            <Icon
                                icon='mail'
                                styles={customIcon}
                            />
                            <span>
                            program@appsinti.com 
                            </span>
                        </a>
                        <a className='contact-item'>
                            <AboutIcon />
                            <span>
                            Rooftop Ventures Llc 
                            </span>
                        </a>
                    </div>

                    <ul className='about'>
                        <li>
                            <a>{translate('About Us')}</a>
                        </li>
                        <li>
                            <a>{translate('Privacy Policy')}</a>
                        </li>
                        <li>
                            <a>{translate('Terms And Conditions')}</a>
                        </li>
                    </ul>
                </Row>
                <Row>
                <div className='copyright'>
                        <span>© 2021. {translateObject({
                            en: 'All rights reserved.',
                            es: 'Todos los derechos reservados'
                        })}</span>
                    </div>
                    <div className='lang'>
                        <Preview 
                            labelStyles = {{
                                color: 'white'
                            }}
                            chevronStyles={{
                                line: {
                                    fill: 'none',
                                    stroke: 'white',
                                },
                            }}
                        />
                    </div>
                </Row>
            </Container>
        </Section>
    )
};

export default Footer;