import React, {useState, useEffect} from 'react';
import {LangDropdown} from '@etiquette-ui/inputs-dropdowns';

import useI18n from './useI18n';

const Preview = ({...props}) => {
  const {selectedLang, setSelectedLang} = useI18n();
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLang ==='es' ? 0 : 1);

  useEffect(() => {
    setSelectedLang(selectedLanguage === 0 ? 'es' : 'en');
  }, [selectedLanguage])
  
  return (
    <><LangDropdown 
      {...props} 
      selectedLang={selectedLanguage} 
      setSelectedLang={setSelectedLanguage} 
    /></>
  )
}
export default Preview;
