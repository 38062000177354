import React, { useState } from 'react';
import {
    Container,
    CenterItem,
    Title,
    MobileIcon,
    NavMenu,
    NavLinks,
    LogoLink
} from './styles';
import { PrimaryButton } from '@etiquette-ui/buttons';
import Logo from '../../images/appsinti-icon.svg';
import MenuIcon from '../../images/menu-Icon.svg';
import CloseIcon from '../../images/close-icon.svg';
import Products from './Products';
import { Link } from 'gatsby';
import useI18n from '../../i18n/useI18n';

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const { translate } = useI18n();

    return (
        <Container click={click}>
            <div className='row'>
                <div className='logo'>
                    <MobileIcon onClick={handleClick}>
                        {click ? <CloseIcon /> : <MenuIcon />}
                    </MobileIcon>

                    <LogoLink to='/'>
                        <CenterItem>
                            <Logo />
                            <Title>Appsinti</Title>
                        </CenterItem>
                    </LogoLink>
                </div>

                <div className='menu'>
                    <NavMenu onClick={handleClick} click={click}>
                        <li>
                            <Products />
                        </li>
                        <li>
                            <CenterItem>
                                <NavLinks to='/about-us/'>{translate('About Us')}</NavLinks>
                            </CenterItem>
                        </li>
                        <li>
                            <CenterItem>
                                <NavLinks to='/#faq'>FAQs</NavLinks>
                            </CenterItem>
                        </li>
                    </NavMenu>
                </div>

                <div className={`button ${ !!click ? '--onBottom' : '--onTop' }`}>
                    <Link to='/#price'>
                        <PrimaryButton>
                            {translate('Get Started')}
                        </PrimaryButton>
                    </Link>
                </div>
            </div>
        </Container >
    )
}

export default Navbar;