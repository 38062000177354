import React from 'react';
import {
    DropdownTitle,
    IconContainer,
    Dropdown,
    DropdownItem,
    DropdownContainer
} from './styles';
import Icon from '@etiquette-ui/icons';
import { Card } from '@etiquette-ui/misc';
import useI18n from '../../i18n/useI18n';

const Products = () => {
    const { translate } = useI18n();
    const menuItems = [
        { link: '/digital-menu/', name: translate('Digital Menu') },
        { link: '/manager-site/', name: translate('Manager Site')},
        { link: '/reservations/', name: translate('Reservations')},
        { link: '/survey-creator/', name: translate('Survey Creator')},
        { link: '/dotell/', name: translate('Dotell')},
        { link: '/dashboard/', name: translate('Dashboard')},
        { link: '/taskme/', name: translate('TaskMe')},
        { link: '/admin-site/', name: translate('Admin Site')},
        { link: '/appsinti/', name: translate('Appsinti')}
    ];

    return (
        <DropdownContainer>
            <DropdownTitle>
                {translate('Products')}
                <IconContainer>
                    <Icon icon='chevron-down' />
                </IconContainer>
            </DropdownTitle>

            <Dropdown className='dropdown'>
                <Card className={'card'}>
                    {menuItems.map(item => (
                        <li>
                            <DropdownItem to={item.link}>
                                {item.name}
                            </DropdownItem>
                        </li>
                    ))}
                </Card>
            </Dropdown>

        </DropdownContainer>
    )
}

export default Products;