import React from 'react';
import styled from 'styled-components';
import useI18n from '../i18n/useI18n';
import { CompleteIcon } from '@etiquette-ui/icons';
import {BaseDialog} from '@etiquette-ui/alert-dialog';
import { RIGHT_DARK, PRIMARY } from '@etiquette-ui/colors'; 

const Row = styled.div`
  display: flex;
  color: black;
`;
const Figure = styled.div`
  margin: auto;
  color: inherit;
  width: fit-content;
  margin-bottom: 20px;
`;
const Title = styled.h1`
  margin: auto;
  color: inherit;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
`;
const Text = styled.p`
  margin: auto;
  color: inherit;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
`;
const CloseButton = styled.div`
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 10px;
  color: ${PRIMARY};
  text-decoration: none;
  transition-property: transform;
  transition-duration: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(.95);
  }
`;

const ConfirmDialog = ({name, lang, translateObject, onSubmit, onClose, open}) => {

  const handleClick = () => {
    onSubmit();
    onClose();
  }
  return (
    <BaseDialog
      open={open}
      onClose={handleClick}
      isResponsive={false}
      dismissible={false}
    >
      <Row>
        <Figure>
        <CompleteIcon
                styles={{
                  svg: { 
                    marginLeft: '3px',
                    width: '80px',
                    height: '80px'
                  },
                  line: {
                    fill: 'none',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: '1.5px',
                    stroke: RIGHT_DARK,
                  }
                }}
              />
        </Figure>
      </Row>
      <Row>
        <Title>
          {name}, {translateObject({
            en: 'thank you for your time!',
            es: '¡Gracias por escribirnos!'
          }, lang)}
        </Title>
      </Row>
      <Row>
        <Text>
          {translateObject({
            en: 'We have recived your message and contact info. We will be in touch soon.',
            es: 'Recibimos tu información. Te contactáremos pronto.'
          }, lang)}
        </Text>
      </Row>
      <Row>
        <CloseButton onClick={handleClick}>
          {translateObject({
            en: 'Close This Modal Window',
            es: 'Cerrar'
          }, lang)}
        </CloseButton>
      </Row>      
    </BaseDialog>
  )
}
export default ConfirmDialog;
