import linkInIcon from '../../images/linkedin-icon.svg';
import { LangDropdown } from '@etiquette-ui/inputs-dropdowns';
import { device, minDevice } from '../../Theme';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 45px;
    padding-bottom: 70px;    

    @media ${minDevice.laptopS} {
        max-width: 1140px;
    }

    @media ${minDevice.desktopM} {
        max-width: 1320px;
    }
  
    .about,
    .contact,
    .description {
        max-width: 100%;
        flex: 0 0 100%;

        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 35px;

        @media ${minDevice.mobile} {
            padding-bottom: 25px;
        }
    }

    .contact,
    .about {
        max-width: 100%;
        flex: 0 0 100%;

        @media ${minDevice.mobileL} {
            max-width: 50%;
            flex: 0 0 50%;
        }

        @media ${minDevice.mobile} {
            max-width: 25%;
            flex: 0 0 25%;
        }
    }

    .description{
        max-width: 100%;
        flex: 0 0 100%;
        order: 3;

        .description-title{
            font-size: 16px;            
            a {                
                font-weight: 500;
                text-decoration: underline;
                transition-property: font-weight;
                transition-duration: .3s;

                &,
                &:hover,
                &:focus {
                    color: white;
                }

                &:hover,
                &:focus {
                    font-weight: bolder;
                }
            }
        }

        @media ${minDevice.mobileL} {
            order: 1;
        }

        @media ${minDevice.mobile} {
            max-width: 33.333333%;
            flex: 0 0 33.333333%;
        }
    }
    .contact{

        margin-left: auto;
        order: 2;

        .contact-title {
            font-weight: 400;
            font-size: 14px;
            margin-top: 0px;
            margin-bottom: 15px;
        }

        .contact-item {
            width: 100%;
            margin-bottom: 15px;
            text-decoration: underline;
            transition-property: font-weight;
            transition-duration: .3s;

            display: flex;
            align-items: flex-end;

            &:hover {
                font-weight: bolder;
            }
        }
    }

    .about {
        order: 1;
        margin: 0px;

        li {
            list-style: none;
            width: 100%;
            padding-bottom: 15px;

            a {
                display: block;
                text-decoration: underline;
                transition-property: font-weight;
                transition-duration: .3s;

                &:hover {
                    font-weight: bolder;
                    cursor: pointer;
                }
            }
        }

        @media ${minDevice.mobile} {
            order: 3;
        }
    }

    .copyright,
    .lang {
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 15px;
        padding-right: 15px;

        @media ${minDevice.mobile} {
            max-width: 25%;
            flex: 0 0 25%;
        }
    }

    .copyright{
        margin-top: -30px;

        @media ${minDevice.mobileL} {
            margin-top: 0px;
            margin-bottom: 10px;
        }

        span{
            font-weight: 400;
            font-size: 12px;
        }
    }
    .lang{
        height: 50px;

        @media ${minDevice.mobileL} {
            margin-left: auto;
        }
    }
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const AboutIcon = styled(linkInIcon)`
    height: 20px;
    width: 20px;
    margin-right: 16px;
`;

const Lang = styled(LangDropdown)`
    color: white;
`;
const customIcon = {
    svg: {
        width: '20px',
        height: '20px',
        marginRight: '16px'
    },
    line: {
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
        stroke: 'white'
    }
};

export {
    Container,
    AboutIcon,
    Lang,
    customIcon,
    Row
};